<div id="main-app">
	<div id="header">
		<img src="assets/header.jpg" height="auto" width="100%">
		<!--<button (click)="getDomesUsers()">test</button>-->
	</div>
	
	<mat-card  class="error-message" *ngIf="errorMessage">
	  <mat-card-content>
		<section class="app-title-section">
			<div>
			  {{ errorMessage }}
			</div>
		</section>
	  </mat-card-content>
	</mat-card>
	
	<mat-card  class="success-message" *ngIf="successMessage">
	  <mat-card-content>
		<section class="app-title-section">
			<div>
			  {{ successMessage }}
			</div>
		</section>
	  </mat-card-content>
	</mat-card>
	
	<mat-card  id="scaned-barcode" class="success-message" *ngIf="scanedBarcode">
	  <mat-card-content>
		<section class="app-title-section">
			<div>
			  {{ scanedBarcode }}
			</div>
		</section>
	  </mat-card-content>
	</mat-card>

	{{ '' // First initialization form block }}
<!--	<mat-card id="otp-send" class="app-form" *ngIf="getAppState()=='0'">
		<div class="app-message" *ngIf="!errorMessage">
		  	{{ printAppState() }}
		</div>
		<div class="app-form-inner">
			<input id="mobile" type = "number" name = "mobile" placeholder = "Αριθμός Κινητού" #mobile>
			<button (click)="sendOTP(mobile.value)">Αποστολή OTP</button>
		</div>
	</mat-card > -->
	
	<mat-card id="otp-send" class="app-form" *ngIf="getAppState()=='0'">
		<div class="app-message" *ngIf="!errorMessage">
		  	{{ printAppState() }}
		</div>
		<div class="app-form-inner">
			<input id="mobile" type = "number" name = "mobile" placeholder = "Αριθμός Κινητού" #mobile>
			<button (click)="apiInitialAuthentication(mobile.value)">Σύνδεση</button>
		</div>
	</mat-card >
	
	
	{{ '' // First initialization form block }}
	
	{{ '' // Second Validate OTP form block }}
	<mat-card id="otp-receive" class="app-form"  *ngIf="getAppState()=='1'">
		<div class="app-message" *ngIf="!errorMessage">
			  	{{ printAppState() }}
		</div>
		<div class="app-form-inner">
			<input id="mobileOTP" type = "number" name = "mobileOTP" placeholder = "Εισαγετε τον κωδικό" #mobileOTP>
			<button (click)="validateOTP(mobileOTP.value)">Εισαγωγή OTP</button>
		</div>
		<div id="resendotp">
			<button (click)="resendOTP()">Λήψη νέου OTP</button>
		</div>
	</mat-card>
	{{ '' // Second Validate OTP form block }}
	
	{{ '' // Third Select user from dropdown }}
	
	<mat-card id="user-select" class="app-form"  *ngIf="getAppState()=='2'">
		<div class="app-message" *ngIf="!errorMessage">
			  	{{ printAppState() }}
		</div>
		<div class="app-form-inner">
			<select id="users" #user>
				<option *ngFor="let user of getUsers()" value={{user.Id}}>{{user.FirstName}} {{user.LastName}}</option>
	  		</select>
			<button (click)="selectUser(user.value)">Επιλογή Χρήστη</button>
			</div>
	</mat-card>
	
	{{ '' // Third Select user from dropdown }}
	
	{{ '' // Fourth check password for login }}
	<mat-card id="user-password" class="app-form"  *ngIf="getAppState()=='3'">
		<div class="app-message" *ngIf="!errorMessage">
			  	{{ printAppState() }}
		</div>
		<div class="app-form-inner">
			<input id="userPassword" type = "number" name = "userPassword" placeholder = "Εισαγετε το PIN σας" #userPIN>
			<button (click)="validatePIN(userPIN.value)">Έλεγχος PIN</button>
		</div>
		<div id="resendotp">
			<button (click)="logOff()">Αλλαγή Χρήστη</button>
		</div>
	</mat-card>
	{{ '' // Fourth check password for login }}
	
	{{ '' // Fifth select domi from all available domes of user }}
	<mat-card id="domi-select" class="app-form"  *ngIf="getAppState()=='4'">
		<div class="app-message" *ngIf="!errorMessage">
			  	{{ printAppState() }}
		</div>
		<div class="app-form-inner">
			<select id="domis" #domis >
				<option *ngFor="let domi of getUserStractures()" value={{domi.Id}}>{{domi.Description}}</option>
	  		</select>
			<button (click)="selectDomi(domis.value)">Επιλογή Δομής</button>
		</div>
	</mat-card>
	{{ '' // Fifth select domi from all available domes of user }}

	{{ '' // Main Scanner App }}
	<div id="main-scanner" *ngIf="getAppState()=='5'">
			
		<mat-card class="inout-control scanner-green" *ngIf="getScanMode()=='0'" (click)="setScanMode('1')">
		  <mat-card-content>
			<section>
				<div><span *ngIf="onlineStatus == 1"><mat-icon>signal_wifi_4_bar</mat-icon></span><span *ngIf="onlineStatus == 0"><mat-icon>signal_wifi_off</mat-icon></span>
					<span>ΠΡΟΣΕΛΕΥΣΗ</span><mat-icon>autorenew</mat-icon>
				</div>
			</section>
		  </mat-card-content>
		</mat-card>
		
		<mat-card class="inout-control scanner-red" *ngIf="getScanMode()=='1'" (click)="setScanMode('0')">
		  <mat-card-content>
			<section>
				<div><span *ngIf="onlineStatus == 1"><mat-icon>signal_wifi_4_bar</mat-icon></span><span *ngIf="onlineStatus == 0"><mat-icon>signal_wifi_off</mat-icon></span>
					<span>ΑΠΟΧΩΡΗΣΗ</span><mat-icon>autorenew</mat-icon>
				</div>
			</section>
		  </mat-card-content>
		</mat-card>
		
		
		<mat-card class="scan-control">
		  <mat-card-content>
			<section>
				<span id="toggle-name">Σαρωτής</span>
			  <mat-slide-toggle id="scan-control" (change)="scanControlChange($event)" checked ="true" *ngIf="getScanState()=='1'">
				Ενεργός
			  </mat-slide-toggle>
			  <mat-slide-toggle id="scan-control" (change)="scanControlChange($event)" checked ="false" *ngIf="getScanState()=='0'">
				Ανενεργός
			  </mat-slide-toggle>
			</section>
		  </mat-card-content>
		</mat-card>
		

				
		<mat-card class="scanner-red" *ngIf="getScanMode()=='1'">
		  <mat-card-content>
			<section>
				<!-- JSQR will populate this element -->
			  	<canvas id="scan-canvas" *ngIf="getScannerType()=='qr'"></canvas>
			  	<div id="output" *ngIf="getScannerType()=='qr'">
				  <div id="outputMessage">No QR code detected.</div>
				  <div hidden=""><b>Data:</b> <span id="outputData">{{ qrcodeDetected }}</span></div>
				</div>
				<!-- JSQR will populate this element -->
			  	<div id="interactive" class="viewport" *ngIf="getScannerType()=='barcode'">
				  <!-- QuaggaJS will populate this element -->
				</div>
			</section>
		  </mat-card-content>
		</mat-card>
		
		<mat-card class="scanner-green" *ngIf="getScanMode()=='0'">
		  <mat-card-content>
			<section>
				<!-- JSQR will populate this element -->
			  	<canvas id="scan-canvas" *ngIf="getScannerType()=='qr'"></canvas>
			  	<div id="output" *ngIf="getScannerType()=='qr'">
				  <div id="outputMessage">No QR code detected.</div>
				  <div hidden=""><b>Data:</b> <span id="outputData">{{ qrcodeDetected }}</span></div>
				</div>
				<!-- JSQR will populate this element -->
			  	<div id="interactive" class="viewport" *ngIf="getScannerType()=='barcode'">
				  <!-- QuaggaJS will populate this element -->
				</div>
			</section>
		  </mat-card-content>
		</mat-card>
		
		<div id="user-panel">
			<div><mat-icon>account_circle</mat-icon><span>{{getCurrentUserName()}}</span></div>
			<div><mat-icon>home</mat-icon><span>{{getCurrentDomiName()}}</span></div>
		</div>
		
		
		<!--<div *ngFor="let barcode of getBarcodes()">{{barcode.Barcode}} {{barcode.FirstName}} {{barcode.LastName}}</div>-->
		
		<mat-card class="warning-message" *ngIf="getScanState()=='0' && !errorMessage">
		  Ενεργοποιήστε το scanner και επιλέξτε Προσέλευση ή Αποχώρηση για τα barcode…
		</mat-card>

		<!--<mat-card class="shopping-cart">
		  <span *ngIf="shoppingCart.isEmpty else shoppingCartItems">Δεν υπάρχουν αποθηκευμένες παρουσίες</span>
		  <ng-template #shoppingCartItems>
			<app-shopping-cart-item
			  *ngFor="let articleAndCount of shoppingCart.items.entries()"
			  [article]="articleAndCount[0]"
			  [count]="articleAndCount[1]">
			</app-shopping-cart-item>
			<div class="shopping-cart-total">
			   Σύνολο Εγγραφών: {{ shoppingCart.totalPrice }}
			</div>
		  </ng-template>
		</mat-card>-->
	</div>

		<mat-expansion-panel hideToggle id="app-settings" (click)="closeScanner()">
		  <mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>settings</mat-icon><span class="settings">Ρυθμίσεις</span>
			</mat-panel-title>
			<mat-panel-description>
			</mat-panel-description>
		  </mat-expansion-panel-header>
		  <p><button (click)="changeDomi()" *ngIf="getAppState() > '4'" >Αλλαγή Δομής</button>
			<button (click)="logOff()" *ngIf="getAppState() > '3'" >Αποσύνδεση Χρήστη</button>
			<button (click)="getData()" *ngIf="getAppState() > '1'" >Ανανέωση Δεδομένων</button>
			<button (click)="getDomiBarcodes()" *ngIf="getAppState() > '4'" >Ανανέωση Barcodes</button>
			<button (click)="resetMobile()" *ngIf="getAppState() > '0'" >Αρχικοποίηση Συσκευής</button>
		<!--	<button (click)="setScannerType('qr')" *ngIf="getScannerType() == 'barcode'" >Αλλαγή σε QR</button>
			<button (click)="setScannerType('barcode')" *ngIf="getScannerType() == 'qr'" >Αλλαγή σε Barcode</button> -->
		  </p>
		</mat-expansion-panel>
		
		<mat-expansion-panel hideToggle id="kids" *ngIf="getAppState() > '4'">
		  <mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>accessibility</mat-icon><span class="settings">Ωφελούμενοι Από Τη Δομή</span>
			</mat-panel-title>
			<mat-panel-description>
			</mat-panel-description>
		  </mat-expansion-panel-header>
			<div *ngFor="let barcode of getBarcodes()">{{barcode.FirstName}} {{barcode.LastName}}</div>
		</mat-expansion-panel>
		
		<mat-expansion-panel hideToggle id="scans" *ngIf="hasScans() == '1' && (getCurrentUser() != '340' || getCurrentUser() != '4228')">
		  <mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>perm_scan_wifi</mat-icon><span class="settings">Εκρεμείς Σαρώσεις</span>
			</mat-panel-title>
			<mat-panel-description>
			</mat-panel-description>
		  </mat-expansion-panel-header>
		  	<button (click)="sendScans()">Αποστολή Σαρώσεων</button>
			<div *ngFor="let scan of getScans()">{{scan.barcodeName}} {{scan.typeDescription}} {{scan.date}} {{scan.time}} </div>
		</mat-expansion-panel>
		
		
		<mat-expansion-panel hideToggle id="scans" *ngIf="getCurrentUser() == '340' || getCurrentUser() == '4228'">
		  <mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>perm_scan_wifi</mat-icon><span class="settings">Εκρεμείς Σαρώσεις Log</span>
			</mat-panel-title>
			<mat-panel-description>
			</mat-panel-description>
		  </mat-expansion-panel-header>
			<div *ngFor="let scan of getScans()">Barcode Name: {{scan.barcodeName}} <br>
												Barcode: {{scan.barcode}} <br>
												Scan Type: {{scan.typeDescription}}<br>
												Scan Date: {{scan.date}}<br>
												Scan Time: {{scan.time}}<br>
												Scan Domi: {{scan.domi}} <br>
												Scan User: {{scan.user}} <br>
												</div>
		</mat-expansion-panel>

<!--	<mat-card class="app-title" id="pre-footer">
	  <mat-card-content>
		<section class="app-title-section">
			<div>
				<img src="assets/footer-espa.jpg" >
			</div>
		</section>
	  </mat-card-content>
	</mat-card>
-->
	
	<div id="footer">
		<img src="assets/footer.jpg" height="auto" width="100%">
	</div>
	
	<div id="version">
		version 24.1
	</div>
	
	{{ '' // Main Scanner App }}

</div>


