import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, NgModule } from '@angular/core';
import { BeepService } from './beep.service';
import Quagga from 'quagga';
import { Article } from './article';
import { ShoppingCart } from './shopping-cart';
import { UpdateService } from './update.service';

import jsQR, { QRCode } from 'jsqr';


///Main commands
///Run ----- ng serve --ssl=true --host=0.0.0.0 --port 4200
///Build ----- ng build --prod --source-map



////API communication imports
import { HttpClient, HttpHeaders, HttpResponse  } from '@angular/common/http';






//Check if appState is set or set it to 0
  if (!localStorage.getItem('appState')){
    localStorage.setItem('appState', '0');
  }
  
//Check if scanState is set or set it to 0
  if (!localStorage.getItem('scanState')){
    localStorage.setItem('scanState', '0');
  }
//Check if scanMode is set or set it to 0
  if (!localStorage.getItem('scanMode')){
    localStorage.setItem('scanMode', '0');
  }
//Check if scannerType is set or set it to barcode - other is qr
  if (!localStorage.getItem('scannerType')){
    localStorage.setItem('scannerType', 'qr');
  }
  localStorage.setItem('scannerType', 'qr');//fix for only qr
//Check if QR scanner is off
  if (!localStorage.getItem('qrActive')){
    localStorage.setItem('qrActive', 'no');
  }  




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {  ///in the appcomponent i declare all functions


	mainURL = 'https://childcare24app.eetaa.gr';  //Live
	//mainURL = 'https://childcare24appstaging.eetaa.gr'; //Test


///  domes = [{"Id":85234,"Description":"Κ.Δ.Α.Π. 8ου Δημοτικού Σχολείου Καλαμάτας"},{"Id":85235,"Description":"Κ.Δ.Α.Π. 9ου Δημοτικού Σχολείου Καλαμάτας"},{"Id":85236,"Description":"Κ.Δ.Α.Π. 10ου Δημοτικού Σχολείου Καλαμάτας"}];
  	//localStorage.setItem('scans', '{"domi":85234,"date":"2021-12-10", "time":"15:12:12", "barcode":"1234567543", "type":"0", "user":"2345"}'); 
  	//localStorage.setItem('scans', '0'); 

	errorMessage: string;
	
	successMessage: string;
	
	scanedBarcode: string;

	shoppingCart: ShoppingCart;
	
	interval: number;
	
	//scans = [];
	
	//////jsqr
	canvasElement: HTMLCanvasElement;
	canvasContext: CanvasRenderingContext2D;
	outputContainer: HTMLDivElement;
	outputMessage: HTMLDivElement;
	outputData: HTMLDivElement;
	video: HTMLVideoElement;

	qrcodeDetected: string;
	//////jsqr	
	
	onlineStatus: number;


	private lastScannedCode: string;
	private lastScannedCodeDate: number;

	constructor(
			  private changeDetectorRef: ChangeDetectorRef,
		      private beepService: BeepService,
		      private updateService: UpdateService,
		      private http: HttpClient) {
	this.shoppingCart = new ShoppingCart();
	}




	////main app code
  ngAfterViewInit(): void {
		localStorage.setItem('scannerType', 'qr');
 ///////For Testing /////localStorage.setItem('scans', '[{"domi":"85234","date":"2021-12-10", "time":"15:12:12", "barcode":"1234567543", "type":"0", "user":"2345"}]'); 
  	
  	//Function to check conectivity
  	
  	//First check
  	if (navigator.onLine) {
		this.onlineStatus = 1;
	} else {
		this.onlineStatus = 0;
	}
	
	//Then every 5 minutes  	
  	setInterval(function(){
		var status = navigator.onLine ? 'You are online!' : 'You are offline!';
		console.log(status);
		if (navigator.onLine) {
			this.onlineStatus = 1;
			//Send the sacans
			if (this.hasScans == '1'){
				this.sendScans();
			}
		} else {
			this.onlineStatus = 0;
		}
	}, 1800000);
  	
  	
  	
  	
  
	///Function to delete messages after 10 seconds
	this.interval = setInterval(() => {
		this.errorMessage = '';
		this.successMessage = '';
	}, 20000);
  		  
  
  if (this.getAppState() == '5' && this.getScanState() == '1'){ ////its 1 nto 9 /// Scanners Block
  		//Check if getusermedia is supported
		if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
		  this.errorMessage = 'getUserMedia is not supported';
		  //return;
		}
		
		//Start scanners
		/*if (localStorage.getItem('scannerType') == 'barcode'){
			this.startQuagga();
		} else {
		  	this.startJSQR();
		}*/
		
		this.startJSQR();
  


		
	  }//End scanners block
  
///Update service
setTimeout(() => {
		  this.updateService.checkForUpdates();
		}, 10000);
///Update service
}
///end of onview init



///Main functions to control different scanners
startQuagga() {
	Quagga.init({
    inputStream: {
      constraints: {
        facingMode: 'environment'
      },
      area: { // defines rectangle of the detection/localization area
        top: '10%',    // top offset
        right: '0%',  // right offset
        left: '0%',   // left offset
        bottom: '10%'  // bottom offset
      },
    },
    decoder: {
      readers: ['code_128_reader'] //delete brackets
    },
  },
  (err) => {
    if (err) {
      this.errorMessage = `Αδυναμία εκίνησης scanner, σφάλμα: ${err}`;
      this.beepService.beep();//make sound
    } else {
      Quagga.start();
      Quagga.onDetected((res) => {
        this.onBarcodeScanned(res.codeResult.code);
      });
    }
  });
}

stopQuagga() {
	Quagga.stop();
}

startJSQR() {
		localStorage.setItem('qrActive', 'yes');
	//if (this.getQrState() == 'yes') {
		this.canvasElement = <HTMLCanvasElement> document.getElementById('scan-canvas');
		//this.canvasElement = <HTMLCanvasElement> document.getElementById('interactive');
		this.canvasContext = this.canvasElement.getContext('2d');
		this.outputContainer = <HTMLDivElement>document.getElementById('output');
		this.outputMessage = <HTMLDivElement>document.getElementById('outputMessage');
		this.outputData = <HTMLDivElement>document.getElementById('outputData');

		this.video = <HTMLVideoElement>document.createElement('video');

		navigator.mediaDevices.getUserMedia(
		
		{
			audio: false,
			video: {
				width: { ideal: 480 },
				height: { ideal: 360 },
				facingMode: 'environment',
				//frameRate: {ideal: 5, max: 10},
			},
		}
		//{ audio: false, video: { facingMode: 'environment' }, frameRate: {ideal: 5, max: 10} }
		).then(async (stream: MediaStream) => {
		    this.video.srcObject = stream;
		    this.video.setAttribute('playsinline', 'true'); // required to tell iOS safari we don't want fullscreen
		    await this.video.play();
		    requestAnimationFrame(this.tick.bind(this));
			});
	//}
	
}

stopJSQR() {
	localStorage.setItem('qrActive', 'no');
}



tick(): void {
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA && this.getQrState() == 'yes' ) {
        this.canvasElement.hidden = false;
        this.outputContainer.hidden = false;

        this.canvasElement.height = this.video.videoHeight;
        this.canvasElement.width = this.video.videoWidth;
        this.canvasContext.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
        const imageData: ImageData = this.canvasContext.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
        const code: QRCode = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
            this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58');
            this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58');
            this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58');
            this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58');
            this.outputMessage.hidden = true;
            this.outputData.parentElement.hidden = false;
            this.qrcodeDetected = code.data;
            //alert('lol');
            //alert(code.data);//neuro
            this.onBarcodeScanned(code.data);
        } else {
            this.outputMessage.hidden = false;
            this.outputData.parentElement.hidden = true;
        }
    }
    requestAnimationFrame(this.tick.bind(this));
}
//}
///JSQR helper functions
drawLine(begin, end, color): void {
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(begin.x, begin.y);
    this.canvasContext.lineTo(end.x, end.y);
    this.canvasContext.lineWidth = 4;
    this.canvasContext.strokeStyle = color;
    this.canvasContext.stroke();
}


///Main functions to control different scanners


///Function to handle scanned barcode from quagga or qr
onBarcodeScanned(code: string) {

    //alert(code);
    // ignore duplicates for an interval of 30 seconds
    const now = new Date().getTime();
    
    // delay 30000 milsecs to avoid duble scans
    if (code === this.lastScannedCode && (now < this.lastScannedCodeDate + 30000)) {
    	//alert('Μόλις σαρώσατε αυτόν τον κωδικό!');
      return;
    }
    
    // Check scanned barcode constrains
    
    if (code.lastIndexOf('249', 0) != 0){ //Scan only barcodes that start from 229 // Live
    //if (code.lastIndexOf('209', 0) != 0){ //Scan only barcodes that start from 209 // test
    	return;
    }
    
    // Check if barcode exists in domi barcodes
    
    let barcodes = this.getBarcodes();
    let barcodeExists = 0;
    let barcodeName = '';
    for (let barcodess of barcodes){
    	if (barcodess.Barcode == code){
    		barcodeExists = 1;
    		barcodeName = barcodess.LastName + ' ' + barcodess.FirstName;
    		break;
    	}
    }
    
	if (barcodeExists == 1){
		this.successMessage = 'Επιτυχής Σάρωση';
		this.scanedBarcode = 'Barcode: ' + barcodeName;
		///make a new article instance and store it to cart
		//const article = { timestamp: now, ean: code, userId: 666, domiId: 10, inOut: Number(localStorage.getItem('scanMode')) }
		//this.shoppingCart.addArticle(article);
		
		//Send parousia
		
		//Get variables
		let domi = localStorage.getItem('currentDomi');
		let user = localStorage.getItem('currentUser');
		let type = this.getScanMode();
		
		//this.apiSendParousia(domi, '2020-09-29', this.getTime(), code, type, user );
		this.apiSendParousia(domi, this.getDate(), this.getTime(), code, type, user, 0 );
		
		 
		this.lastScannedCode = code;
		this.lastScannedCodeDate = now;//set current timestamp
		this.beepService.beep();//make sound
		this.changeDetectorRef.detectChanges();//set quagga ready for next scan
		
	} else {
		this.beepService.beep();//make sound
		this.errorMessage = 'Δεν υπάρχει το barcode στη δομή';
		this.changeDetectorRef.detectChanges();//set quagga ready for next scan
	}
}
//End of barcode scanned
	
	
	
	
	  /*
  code to set and get app state its
  0 - First time you open the app and you see only the form to enter mobile to get OTP
  1 - Form to enter OTP you received
  2 - User selection form
  3 - User form to enter password - if fail go back to user selection form
  4 - Domi selection form
  5 - Main app
  
  We add buttons to change state of the app 1 - Change domi 2 - Logout 3 - Unpair device and back to state 0  
  */
  
  setAppState(appState :string)	{
  		localStorage.setItem('appState', appState);
  }
  
  getAppState()	{
  	return localStorage.getItem('appState');
  }
  
  printAppState(){
  	var appState = localStorage.getItem('appState');
  	if (appState == '0'){
  		return "Αρχικοποίηση Εφαρμογής - Εισάγετε αριθμό συσκευής για σύνδεση";
  	} else if (appState == '1'){
  		return "Εισαγωγή κωδικού OTP για επιβεβαίωση";
  	} else if (appState == '2'){
  		return "Παρακαλούμε επιλέξτε χρήστη";
  	} else if (appState == '3'){
  		return "Παρακαλούμε εισάγετε τον κωδικό PIN";
  	} else if (appState == '4'){
  		return "Παρακαλούμε επιλέξτε δομή";
  	} else {//we are at state 5 now in main scanning
		return "Σαρώστε ένα barcode";  	
  	}
  
  }
	
	sendOTP(mobile :string){///needs work
		localStorage.setItem('tempmobile', mobile);
		this.apiGetOtp(mobile);
	}
	
	resendOTP(){
		let tempmobile = localStorage.getItem('tempmobile')
		this.apiGetOtp(tempmobile);
		//this.setAppState('0');
	}
	
	validateOTP(mobileOTP :string){
		let otp = localStorage.getItem('OTP')	
		if ( mobileOTP == otp){
			let tempmobile = localStorage.getItem('tempmobile')
			this.apiInitialAuthentication(tempmobile);
		} else {
			this.errorMessage = 'Λάθος Κωδικός OTP';
		}
	}
	
	selectUser(user :string){
		//alert(user);
		localStorage.setItem('currentUser', user);
		this.setAppState('3');
		//remove old data if exist
		localStorage.removeItem('currentDomi');
		localStorage.removeItem('barcodes');
	}
	
	validatePIN(pin :string){
		//alert(user);
		let currentUser = localStorage.getItem('currentUser');
		//console.log(currentUser);
		let userPIN = '0';
		let users = JSON.parse(localStorage.getItem('users'));
		for (let user of users){
			if (user.Id == currentUser){
				userPIN = user.Pin;
			}
		}
		if (userPIN == pin){
			this.successMessage = 'Επιτυχής Σύνδεση';
			localStorage.removeItem('currentDomi');
			for (let user of users){
				if (user.Id == currentUser){
					localStorage.removeItem('currentUserDomes');
					localStorage.setItem('currentUserDomes', JSON.stringify(user.userStructures));//add user domes
					localStorage.setItem('currentUserName', user.FirstName + ' ' + user.LastName);//Add current user name
				}
			}
			this.setAppState('4');
		} else {
			this.errorMessage = 'Λάθος Κωδικός';
		}
	}
	
	selectDomi(domi :string){
		//alert(user);
		localStorage.setItem('currentDomi', domi);
		//Add current domi name
		let domes = JSON.parse(localStorage.getItem('currentUserDomes'));
		for (let domis of domes){
			if (domis.Id == domi){
				localStorage.setItem('currentDomiName', domis.Description);
			}
			}
		this.getDomiBarcodes(); //Get all the barcodes of domi
		this.setAppState('5');
	}
	
	//test
	getTime(){
		let d = new Date();
		return d.toLocaleTimeString('en-GB');
	}
	
	getDate(){
		let d = new Date();
		let mm = d.getMonth() + 1;
		let dd = d.getDate();
		let yy = d.getFullYear();
		//return d.toLocaleDateString('en-GB');
		return yy + '-' + mm + '-' + dd;
	}
	
	
	
	///Functions to control scanner
    closeScanner() {
    	if (this.getScannerType() == 'qr'){
  			this.stopJSQR();
  			//window.location.reload();
  		} else {
  			this.stopQuagga();
  			//window.location.reload();
  		}
    	localStorage.setItem('scanState', '0');
  	}
  	
  	openScanner() {
  		if (this.getScannerType() == 'qr'){
  			localStorage.setItem('qrActive', 'yes');
  			this.startJSQR();
  			//window.location.reload();
  		} else {
  			this.startQuagga();
  			//window.location.reload();
  		}
    	localStorage.setItem('scanState', '1');
  	}
  	
  	getQrState() {
  		return localStorage.getItem('qrActive');
  	}
  	
  	
  	getScanState() {
  		return localStorage.getItem('scanState');
  	}
  	
  	getScannerType() {
  		return localStorage.getItem('scannerType');
  	}
  	
  	setScannerType (scannerType :string) {
  		if (scannerType == 'qr'){
  			localStorage.setItem('scannerType', 'qr');
  			window.location.reload();
  		} else {
  			localStorage.setItem('scannerType', 'barcode');
  			window.location.reload();
  		}
  	}
  	
  	///Functions to scan mode
  	
  	setScanMode(mode :string) {
    	localStorage.setItem('scanMode', mode);
    	localStorage.setItem('scanState', '0');
  	}
  	
  	getScanMode() {
  		return localStorage.getItem('scanMode');
  	}
  	
  	getUsers() {
  		return 	JSON.parse(localStorage.getItem('users'));
  	}
  	
  	getCurrentUser() {
  		return localStorage.getItem('currentUser');
  	}
  	
  	getCurrentDomi() {
  		return localStorage.getItem('currentDomi');
  	}
  	
  	getCurrentUserName() {
  		return localStorage.getItem('currentUserName');
  	}
  	
  	getCurrentDomiName() {
  		return localStorage.getItem('currentDomiName');
  	}
  	
  	getUserStractures() {
  		return JSON.parse(localStorage.getItem('currentUserDomes'));
  	}
  	
  	getBarcodes() {
  		return JSON.parse(localStorage.getItem('barcodes'));
  	}
  	
  	//Functions to control logoff and unpair
  	
  	logOff() {
  		localStorage.removeItem('currentUser');
  		localStorage.removeItem('currentDomi');
  		localStorage.removeItem('currentUserName');
		localStorage.removeItem('currentDomiName');
		localStorage.removeItem('barcodes');
  		this.setAppState('2')
  		localStorage.setItem('scanState', '0');
  	}
  	
  	changeDomi() {
  		localStorage.removeItem('currentDomi');
		localStorage.removeItem('currentDomiName');
		localStorage.removeItem('barcodes');
  		this.setAppState('4')
  		localStorage.setItem('scanState', '0');
  	}
  	
  	
  	///Offline Scans Functions
  	storeScan(domi, date, time, barcode, type, user ){
  		//first get an array of all the scans from local storage
  		let scans = [];
  		if (localStorage.getItem('scans')){
  			scans = JSON.parse(localStorage.getItem('scans'));
  		}
  		//empty scans local storage array
  		localStorage.removeItem('scans');
  		//Find user name and surname from barcode
		let barcodes = this.getBarcodes();
		let barcodeName = '';
		for (let barcodess of barcodes){
			if (barcodess.Barcode == barcode){
				barcodeName = barcodess.LastName + ' ' + barcodess.FirstName;
				break;
			}
		}
		//Add description for scan type
		let typeDescription = '';
		if (type == 0){
			typeDescription = 'Πρ.';	
		} else {
			typeDescription = 'Απ.';
		}
  		//push new entry to array
  		scans.push({'domi':domi,'date':date,'time':time,'barcode':barcode,'type':type,'user':user, 'barcodeName':barcodeName, 'typeDescription':typeDescription});
  		//store new array to local storage  	
		localStorage.setItem('scans', JSON.stringify(scans)); 	
  	}
  	
  	hasScans() {
  		if (localStorage.getItem('scans')){
  			return '1';
  		} else {
  			return '0';
  		}
  	}
  	
  	sendScans() {
  		//first get an array of all the scans from local storage
  		let scans = JSON.parse(localStorage.getItem('scans'));
  		//empty scans local storage array
  		localStorage.removeItem('scans');
  		//Iterrate over pending scans and resend them
  		for (let scan of scans) {
        	this.apiSendParousia(scan.domi, scan.date, scan.time, scan.barcode, scan.type, scan.user, 1 );
    	}
  	}
  	
  	getScans() {
  		return JSON.parse(localStorage.getItem('scans'));
  	}
  	
  	///Offline Scans Functions
  	
  	


	///control function of scanner toggle
	scanControlChange(value) {
	  if (value.checked == true){
		this.openScanner();  
	  } else {
	  	this.closeScanner();
	  }
	}  



	////Code for API calls

	
	///API functions
	apiGetOtp(mobile){
	  	this.http.get(this.mainURL + '/php/req.php?action=otp&mobile=' + mobile).subscribe({
		    next: (data : any) => {
		    	//data = JSON.parse(JSON.stringify(data));
		        //console.log(data.message);
		        if (data.respcode == 0){
		        	localStorage.setItem('OTP', data.message);
		        	this.successMessage = 'Ο κωδικός OTP είναι" '+data.message;
		        	this.setAppState('1');		        	
		        } else {
		        	this.errorMessage = data.message;
		        	}
		    },
		    error: error => {
		        this.errorMessage = error.message;
		        console.error('Αδυναμία επικοινωνείας με το σύστημα. ', error);
		    }
    	});
	  }
	
	
	
	  apiInitialAuthentication(mobile){
	  	this.http.get(this.mainURL + '/php/req.php?action=pair&mobile=' + mobile).subscribe({
		    next: (data : any) => {
		    	//data = JSON.parse(JSON.stringify(data));
		        //console.log(data.message);
		        if (data.respcode == 0){
		        	this.successMessage = data.message;
		        	localStorage.setItem('mobile', mobile);	
		        	this.getData(); //Fetch initial data for domes and users
		        	this.setAppState('2');		        	
		        } else {
		        	this.errorMessage = data.message;
		        	}
		    },
		    error: error => {
		        this.errorMessage = error.message;
		        console.error('There was an error!', error);
		    }
    	});
	  }
	  
	  apiSendParousia(domi, date, time, barcode, type, user, ispending ){
	  	//alert('test');
	  	//this.storeScan(domi, date, time, barcode, type, user );
	  	this.http.get(this.mainURL + '/php/req.php?action=parousianew&domi='+domi+'&date='+date+'&time='+time+'&barcode='+barcode+'&type='+type+'&user='+user+'&ispending='+ispending).subscribe({
		    next: (data : any) => {
		    	//alert(this.mainURL + '/php1/req.php?action=parousia&domi='+domi+'&date='+date+'&time='+time+'&barcode='+barcode+'&type='+type+'&user='+user);
		    	//data = JSON.parse(JSON.stringify(data));
		    		//console.log('/php/req.php?action=parousianew&domi='+domi+'&date='+date+'&time='+time+'&barcode='+barcode+'&type='+type+'&user='+user+'&ispending='+ispending);
		        //console.log(data.message);
		        //console.log(data.respcode);
		        if (data.respcode == 0){
		        	this.successMessage = data.message;
		        	//console.log('success');
		        } else {
		        	this.errorMessage = data.message;
		        	this.storeScan(domi, date, time, barcode, type, user );
		        	//console.log('fail, added to localstorage');
		        	}
		    },
		    error: error => {
		    	this.storeScan(domi, date, time, barcode, type, user );
		        //this.errorMessage = error.message;
		        //console.error('There was an error!', error);
		        //this.storeScan(domi, date, time, barcode, type, user );
		    }
    	});
	  }
	  
	 resetMobile() {
	 	let mobile = localStorage.getItem('mobile');
	 	this.http.get(this.mainURL + '/php/req.php?action=unpair&mobile=' + mobile).subscribe({
		    next: (data : any) => {
		    	//localStorage.setItem('data', JSON.stringify(data));
		    	//data = JSON.parse(localStorage.getItem('data'));
		    	//data = map(data.parseJson);
		        //console.log(data.message);
		        if (data.respcode == 0){
		        	this.successMessage = data.message;
		        	this.setAppState('0')
		        	localStorage.removeItem('mobile');
		        	localStorage.removeItem('users');
		        	localStorage.removeItem('currentUser');
		        	localStorage.removeItem('currentDomi');
		        	localStorage.removeItem('currentUserName');
		        	localStorage.removeItem('currentDomiName');
		        	localStorage.removeItem('OTP');
		        	localStorage.removeItem('barcodes');
		        	localStorage.removeItem('tempmobile');
  					localStorage.setItem('scanState', '0');	        	
		        } else {
		        	this.errorMessage = data.message;
		        	this.setAppState('0')
		        	}
		    },
		    error: error => {
		        this.errorMessage = error.message;
		        console.error('There was an error!', error);
		    }
    	});
   	}
   	
   	getDomesUsers() {
	 	let mobile = localStorage.getItem('mobile');
	 	this.http.get(this.mainURL + '/php/req.php?action=data&mobile=' + mobile).subscribe({
		    next: data => {
		        //console.log(data[0]);
		        localStorage.setItem('users', JSON.stringify(data)); 
		    },
		    error: error => {
		        this.errorMessage = error.message;
		        console.error('There was an error!', error);
		    }
    	});
   	}
   	
   	getDomiBarcodes() {
	 	let domi = localStorage.getItem('currentDomi');
	 	//console.log(domi);
	 	this.http.get(this.mainURL + '/php/req.php?action=barcodes&domi=' + domi).subscribe({
		    next: data => {
		        //console.log(data[0]);
		        localStorage.setItem('barcodes', JSON.stringify(data)); 
		    },
		    error: error => {
		        this.errorMessage = error.message;
		        console.error('There was an error!', error);
		    }
    	});
   	}
   	
   	getData() {
   		this.getDomesUsers();
   	}
   	
   	
   	

}///Closing of main function
