<div class="shopping-cart-item">

  <div class="article-barcode">{{ article.ean }}</div>	<!-- name->timestamp -->

  <div class="article-time">{{ article.timestamp | date:'dd-M-yyyy hh:mm:ss' }}</div>	<!-- name->timestamp -->
  
  <div class="article-action-in" *ngIf="article.inOut == 0"><mat-icon>keyboard_arrow_left</mat-icon></div>	<!-- In Or Out -->
  <div class="article-action-out" *ngIf="article.inOut == 1"><mat-icon>keyboard_arrow_right</mat-icon></div>	<!-- In Or Out -->

  <!--<div class="article-count">{{ count }}</div>-->
</div>
