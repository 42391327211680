import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(private swUpdate: SwUpdate) {
    swUpdate.available.subscribe(event => {
      if (window.confirm(`Μια νέα έκδοση της εφαρμογής είναι διαθέσιμη, θέλετε να την κατεβάσετε;`)) {
        swUpdate.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  checkForUpdates() {
    this.swUpdate.checkForUpdate();
  }
}
